<template>
  <div>
  <Header/>
  <Body/>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import Body from '@/components/Body.vue'

export default {
  name: 'Home',
  components: {
    Header,
    Body
  }
}
</script>
