<template>
    <div class="header">
       <p class="title">Hello.</p>   
        <ul class="menu">
        <li>Skill</li>
        <li>Experience</li>
        <li>Education</li>
        <li>Biography</li>
    </ul>
    </div>

</template>
<script>
export default {
    
}
</script>