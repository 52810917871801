<template>
  <div>
    <div class="title-landing">Wellcome to my website</div> 
    <div class="paragraf">
    <transition-group name="cell" tag="div" class="container">
      <div v-for="cell in cells" :key="cell.id" class="cell" 
      :class="[cell.space]">
        {{ cell.number }}
      </div>
    </transition-group>
    <div class="sent-email">
    <a href="mailto:agungrizki0@gmail.com"> sent email to me</a>
    </div>
    </div>
    <div class="frontend-container">
      <div>
      <div>
        <span class="title-job">Frontend Developer</span>
        <img src="@/assets/bullet.png" class="bullet-job">
      </div> 
        <div class="subtitle-job">With Vue.js React Native And Android Native</div>
      </div>
    </div>
    <div class="backend-container">
      <div>
      <div>
        <span class="title-job">Backend Developer</span>
        <img src="@/assets/bullet.png" class="bullet-job">
      </div> 
        <div class="subtitle-job">With Laravel ORM Socket.oi Redist</div>
      </div>
    </div>
    <div class="devops-container">
      <div>
      <div>
        <span class="title-job">DevOps</span>
        <img src="@/assets/bullet.png" class="bullet-job">
      </div> 
        <div class="subtitle-job">With AWS EC2 RDS s3</div>
      </div>
    </div>
    <!-- <div class="card-landing">  
            <div class="inner-box">
              <div class="circle" :style="styles" /> 
  </div>
    </div> -->
    <!-- <div class="card-landing-2">  
            <div class="inner-box">
              <div class="circle-2" :style="styles" /> 
              <div class="circle-2" :style="styles2" /> 
              <div class="circle-2" :style="styles3" /> 
  </div>
    </div> -->
  </div>
</template>
<script>
export default {
  el: "#staggered-list-demo",
  data() {
    return {
     cells:[],
        x: 0,
        y: 0,
        viewportHeight: 1,
        viewportWidth: 1
    };
  },
  computed: {
    computedList: function () {
      var vm = this;
      return this.list.filter(function (item) {
        return item.msg.toLowerCase().indexOf(vm.query.toLowerCase()) !== -1;
      });
    },
    myScript: function () {
      return this.myText.split("").map((data,index)=>{
      return {
          id: index,
          number: data,
          space:data===" "?"space":"",
        };
      });
    },
    myText(){
      return "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur?"
      },
    styles () {
        return {
          left: percent(this.x, this.viewportWidth),
          top: percent(this.y, this.viewportHeight)
        }

        function percent (value, total) {
          return Math.round((value * 100 / (total*200/100))) + '%'
        }
      },
    styles2 () {
        return {
          left: percent(this.x, this.viewportWidth/2),
          top: percent(this.y, this.viewportHeight*2)
        }

        function percent (value, total) {
          return Math.round((value * 100 / (total*200/100))) + '%'
        }
      },
    styles3 () {
        return {
          left: percent(this.x, this.viewportWidth/4),
          top: percent(this.y, this.viewportHeight*4)
        }

        function percent (value, total) {
          return Math.round((value * 100 / (total*200/100))) + '%'
        }
      }
  },
  methods: {
    shuffle: function () {
      this.cells = this.myScript;
    },
    shuffleArray(array) {
      let currentIndex = array.length,
        randomIndex;

      // While there remain elements to shuffle...
      while (currentIndex != 0) {
        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        // And swap it with the current element.
        [array[currentIndex], array[randomIndex]] = [
          array[randomIndex],
          array[currentIndex],
        ];
      }

      return array;
    },
      onMouseMove (ev) { 
        this.x = ev.clientX
        this.y = ev.clientY
      },

      updateViewportSize () {
        this.viewportHeight = window.innerHeight
        this.viewportWidth = window.innerWidth
      }
  },

    created () {
      this.updateViewportSize()
    },
  mounted(){
    this.cells=this.shuffleArray(this.myText.split("").map((data,index)=>{
      return {
          id: index,
          number: data,
          space:data===" "?"space":"",
        };
      }))
    setTimeout(() => {
    this.shuffle();
    }, 1500);
    //  setTimeout(() => {
    //   document.addEventListener('mousemove', this.onMouseMove)
    //   window.addEventListener('resize', this.updateViewportSize)
    // }, 6000);
  },

    unmounted () {
      document.removeEventListener('mousemove', this.onMouseMove)
      window.removeEventListener('resize', this.updateViewportSize)
    },
};
</script>